import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import { Box } from 'components/Box';
import { Text } from 'components/Text';
import BackgroundImage from 'gatsby-background-image';
import { Container } from 'components/Container';
import { ContainerImageWide } from 'components/ContainerImageWide';
import { CallToAction } from 'components/CallToAction';
export const pageQuery = graphql`
  query AnsarFR {
    heroImage: file(relativePath: {eq: "project-ansar-hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footerImage: file(relativePath: {eq: "project-ansar-footer.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Devenez un Ambassadeur de UMMATY" description="À travers son programme ZAKAT, UMMATY vous offre la possibilité de remplir cette obligation en un seul clic. Nous veillons à ce que 100% des montants de vos dons Ansar (sans frais administratifs) soient dépensés pour les catégories de bénéficiaires mentionnées dans le Saint Coran." mdxType="SEO" />
    <ContainerImageWide fluid={props.data.heroImage.childImageSharp.fluid} mdxType="ContainerImageWide">
  <CallToAction purpose="ansar" title="Ansar Ummaty" text="Fonds Ansar: Un seul don, de multiple causes supportées" buttonText="Faire un Don" pagePath={props.path} mdxType="CallToAction" />
    </ContainerImageWide>
    <Container mdxType="Container">
  <Text mb={0} mdxType="Text">
    <div>
      Faites partie de nos soutiens exceptionnels: un 
      <strong> fonds unique</strong> qui aidera à servir plusieurs causes et à
      obtenir les <strong>récompenses</strong> pour: <strong>l'eau potable</strong> (puits d'eau), les soins aux
      <strong> orphelins</strong>, <strong>l'éducation</strong>, les programmes <strong>d'aide alimentaire</strong>, le soutien aux
      <strong> réfugiés</strong> et bien plus encore. 
    </div>
    <br />
    <div>
     Nous rêvons tous de pouvoir aider les nécessiteux, sauver des vies 
     et soulager la souffrance de nos frères et sœurs à travers le monde. 
     Mais les besoins sont nombreux et on ne sait souvent pas par où commencer. 
     UMMATY vous offre l'opportunité de pouvoir, à travers une seule action, 
     un seul don, contribuer à la réalisation de différents projets humanitaires, 
     dans différentes parties du monde.
     Le fonds ANSAR a été créé pour soutenir et contribuer au développement 
     des actions de UMMATY. Ces actions comprennent l'approvisionnement en eau potable, 
     la fourniture d'aides alimentaires aux réfugiés et aux populations les plus vulnérables, 
     la prise en charge d’orphelins et l'éducation.
    </div>
    <br />
    <h3>
     1 Don = Récompenses pour les projets d'Eau potable + Soins aux orphelins + Education + Aides aux réfugiés + Aides alimentaires
    </h3>
    <h2>.. et ALLAH multiplie les récompenses pour qui Il veut.</h2>
  </Text>
  <></>
    </Container>
    <div style={{
      "backgroundColor": "#F4F7F8",
      "width": "100%"
    }}>
  <Container mdxType="Container">
    <Text mb={0} mdxType="Text">
      <div>
        Bien que UMMATY soit <strong>une organisation à 100% bénévole</strong>, 
        sans un seul dollar dépensé en salaires, la réalisation de nos projets implique des coûts importants. 
        Il s'agit notamment de la logistique, de la communication, de la collecte de fonds, etc. 
        Sans dépenser sur ces aspects, les différents projets de UMMATY qui profitent à des dizaines 
        de milliers de personnes à travers le monde ne pourraient jamais être achevés. C'est pourquoi 
        le <strong>Fonds ANSAR a été créé, afin de couvrir tous ces types de frais de fonctionnement. </strong> 
        Cela nous permet également de proposer et d'exécuter des projets avec <strong>0% de frais d'administration </strong>
        déduits de vos dons.
      </div>
      <br />
      <div>
        En faisant un don unique au fonds ANSAR, vous rejoignez les supporters de UMMATY, 
        et inshaAllah, obtiendrez les récompenses pour tous nos projets actuels et futurs, 
        car ce fonds sera utilisé pour soutenir notre capacité à les développer.
      </div>
    </Text>
    <></>
  </Container>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      